function setButtonLoading($this) {
    var submitButton = $this.find('button[type="submit"], #submit').not(".no-loading");

    if (submitButton.length) {
        submitButton.prop('disabled', true).append('<i class="fas fa-spinner-third fa-spin ms-1"></i>');
    }
}

$('form[method="post"]').on('submit', function (ev) {
    setButtonLoading($(this));
});

$('.select2').select2({
    theme: 'bootstrap-5',
    width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
    placeholder: $(this).data('placeholder'),
    // dropdownParent: $(this).closest('.modal').length ? $(this).closest('.modal') : $(this).closest('.select2').length ? $(this).closest('.select2') : $(document.body),
    // dropdownParent: $(document.body)
});

// $('.select2').on('select2:open', function (e) {
//     const evt = "scroll.select2";
//     $(e.target).parents().off(evt);
//     $(window).off(evt);
// });

$('#mobile-menu-bars').on('click', function () {
    $('#user-sidebar').toggleClass('active');

    $(window).on('resize', function () {
        if ($(window).width() > 768) {
            $('#user-sidebar').removeClass('active');
        }
    });
});

$('.content-wrapper, .user-footer').on('click', function () {
    if ($(window).width() < 768) {
        if ($('#user-sidebar').hasClass('active')) {
            $('#user-sidebar').removeClass('active');
        }
    }
});

$('#logout').on('click', function () {
    $('#logout-form').submit();
});

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
